<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('MailBoxesCreate')"
    @edit="(id) => onEdit('MailBoxesEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'MailBoxes',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'MailBoxes',
      tableCaption: 'Почтовые ящики',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Почтовые ящики',
        },
      ],
      tableHeaders: [
        { text: 'Название почт. ящика', alias: 'name', order: 'name' },
        { text: 'Имя пользователя', alias: 'userName', order: 'userName' },
        { text: 'Сервер', alias: 'server', order: 'server' },
        { text: 'Статус', alias: 'status', order: 'status' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
